import arroRight from '../../../assets/icons/arrows/Vector 1.svg';
import arroLeft from '../../../assets/icons/arrows/Vector 2.svg';
import React from 'react';
import css from './SearchResultsPanel.module.css';
import { ListingCard } from '../../../components';



export default class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardRenderSizes: [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${50}vw`,
        `(max-width: 1920px) ${62.5 / 2}vw`,
        `${62.5 / 3}vw`,
      ].join(', '),
      current: 0,
      length: props.array.length,
      sortedListings: []
    }
  }
  nextSlide = () => {
    this.setState(this.state.current === this.state.length - 1 ? { current: 0 }
      : { current: this.state.current + 1 });
  };
  prevSlide = () => {
    this.setState(this.state.current === 0 ? { current: this.state.length - 1 }
      : { current: this.state.current - 1 });
  };

   sortByPriceAscending = (objects) => {
    return objects.sort((a, b) => a.attributes.price.amount - b.attributes.price.amount);
  }
  render() {
    return (
      <section className={css.cards_slider}>
        <div className={css.slider_btns}>
          <div className={css.slider__controls}>
            <button className={css.btn} onClick={this.prevSlide}
            data-elbaction={`click:click destp-switchrtype-${this.props.array[this.state.current].attributes.title}`}>
            <img src={arroLeft}  />
            </button>
            <button className={css.btn} onClick={this.nextSlide}
            data-elbaction={`click:click destp-switchrtype-${this.props.array[this.state.current].attributes.title}`}>
            <img src={arroRight} />
            </button>
          </div>
        </div>
        {
      this.sortByPriceAscending(this.props.array).map((slide, index) => {
        return (
          <div
            className={index === this.state.current ? 'slide active' : 'slide'}
            key={index}
          >
            {index === this.state.current && (
             <div data-elbaction={`click:click destp-list-${slide?.attributes?.title}-uuid-${slide?.id?.uuid}`} >
              <ListingCard
                className={css.listingCard}
                key={slide.id.uuid}
                listing={slide}
                renderSizes={this.state.cardRenderSizes}
                setActiveListing={this.props.setActiveListing}
              />
            </div>
            )}
          </div>
        );
      })
    }
      </section >
    )
  }

}
